<template>
  <div
    class="card col-12 mb-0"
    style="height: auto;width:100%"
    v-if="promotionForm !== null"
  >
    <!-- /card-header -->
    <div class="card-body p-0" v-loading="isLoading">
      <div>
        <label>Title:</label>
        <span>{{ promotionForm.title }}</span>
      </div>
      <div>
        <div>
          <span>{{ promotionForm.type | promotType }}|</span>
          <span>{{ promotionForm.recipientType | promotRecipType }}|</span>
        </div>
        <div>
          {{promotionForm.recipient}}
        </div>
      </div>
      <div v-if="isvisibleLinkRedirec(promotionForm.type)">
        <label class="d-block">Redirec link</label>
        <el-input
          type="textarea"
          style="width:100%;"
          :autosize="{ minRows: 2 }"
          placeholder="Link redirec here!"
          v-model="promotionForm.linkRedirect"
        ></el-input>
      </div>
      <div v-if="isvisibleBody(promotionForm.type)">
        <label class="d-block">Notification Body</label>
        <el-input
          type="textarea"
          style="width:100%;"
          :autosize="{ minRows: 2 }"
          placeholder="Body here!"
          v-model="promotionForm.body"
        ></el-input>
      </div>
      <div class="mt-4" v-if="isvisibleBodyDetail(promotionForm.type)">
        <div class="mr-3">
          <label>Popup Body</label>
        </div>
        <div style="height:60vh;">
          <ckeditor
            v-model="promotionForm.bodyData"
            :config="editorConfig"
            editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js"
          ></ckeditor>
        </div>
      </div>

      <div>
        <button class="btn btn-success m-2" @click="saveChange()">Save</button>

        <button class="btn btn-success m-2" @click="saveAndSend()">
          Save and Send
        </button>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
</template>
<script>
import promotApi from "@/api/common/promotionNotification";
import commonAct from "@/commonActionHandle.js";
import baseComponent from "@/scripts/baseComponent";

export default {
  extends: baseComponent,
  data() {
    return {
      editorConfig: {},
    };
  },
  methods: {
    saveChange() {
      if (this.$refs.promotionContent) {
        console.log(this.$refs.promotionContent);
        this.promotionForm.bodyData = this.$refs.promotionContent.getContent();
      }
      promotApi
        .savePromotion(this.promotionForm)
        .then((res) => {
          if (res.data.result == 0) {
            this.$emit("SaveEditorSuccess", res.data.data);
            this.showSuccessToast(`<div class="text-left">Succees</div>`);
          } else {
            commonAct.showError(res.data.message);
          }
        })
        .catch((err) => {
          commonAct.showError(err);
        });
    },
    saveAndSend() {
      if (this.$refs.promotionContent) {
        this.promotionForm.bodyData = this.$refs.promotionContent.getContent();
      }
      promotApi
        .saveAndSendPromot(this.promotionForm)
        .then((res) => {
          if (res.data.result == 0) {
            this.$emit("SaveEditorSuccess", res.data.data);
            this.showSuccessToast(`<div class="text-left">Succees</div>`);
          } else {
            commonAct.showError(res.data.message);
          }
        })
        .catch((err) => {
          commonAct.showError(err);
        });
    },
    isvisibleLinkRedirec(type) {
      switch (type) {
        case 3:
          return true;
        default:
          return false;
      }
    },
    isvisibleBody(type) {
      switch (type) {
        case 1:
        case 2:
        case 4:
        case 3:
          return true;
        default:
          return false;
      }
    },
    isvisibleBodyDetail(type) {
      switch (type) {
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
          return true;
        default:
          return false;
      }
    },
  },
  components: {},
  props: {
    currentCommand: {
      type: String,
      default: "",
    },
    promotionForm: {
      type: Object,
      default: null,
    },
  },
  watch: {
    promotionForm: function(val) {
      if (val) {
        if (this.$refs.promotionContent && val.bodyData) {
          this.$refs.promotionContent.setContent(val.bodyData);
        }
      }
    },
  },
};
</script>
